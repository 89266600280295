import React, { useState, useEffect } from 'react';
import RBody from '../../../../components/RBody';
import { useStyles } from './styles';
import { useAlert } from '../../../../services/apiService';
import { useNavigate } from 'react-router-dom';

const IniciarViagemPage = () => {
  const { callApi } = useAlert();
  const styles = useStyles();
  const [rotas, setRotas] = useState([]);

  const navigate = useNavigate(); 

  useEffect(() => {
    handleRotas();
  }, []);

  const handleRotas = async () => {
    try {
      const result = await callApi({
        path: '/rotas',
        method: 'GET',
        body: null,
        need_token: true
      });
      if (result.success) {
        if (result.data) {
          setRotas(result.data.reverse());
        } else {
          console.error('Dados de rotas não encontrados na resposta da API.');
        }
      } else {
        console.error('Erro ao obter rotas:', result.message);
      }
    } catch (error) {
      console.error('Erro ao obter rotas:', error);
    }
  };

  const createViagem = async (rota) => {
    try {

      const clientes_rota = rota.clientes;

      const clientes_concluidos = clientes_rota.map(cliente => ({
        cliente: cliente,
        concluido: false
      }));

      const now = new Date();
      const brasiliaTime = now.toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' });

      const body = {
        clientes_concluidos: clientes_concluidos,
        data_inicio: brasiliaTime,
        rota: rota,
      }

      const result = await callApi({
        path: '/viagem/store',
        method: 'POST',
        body: body,
        need_token: true,
        alerta: true
      });
      if (result.success) {
        if (result.data) {
          setTimeout(() => {
            navigate(`/rotas/viagens/listar/${result.data.codigo}`);
          }, 800);
        } else {
          console.error('Erro ao criar viagem.');
        }
      } else {
        console.error('Erro ao criar viagem:', result.message);
      }
    } catch (error) {
      console.error('Erro ao criar viagem:', error);
    }
  };

  return (
    <RBody
      title="Iniciar Viagem"
      titleColor='#eee'
    >

      <div style={{ backgroundColor: '#eee', borderRadius: '50px', margin: '100px 5% 100px 5%', padding: '1px' }}>
        <h1 style={{ color: '#2ABFB0', textAlign: 'center' }}>
          Selecione uma rota para iniciar a viagem
        </h1>
      </div>
      {rotas.map(item => (
        item.ativo ? (
          <div
            style={styles.containerStyle}
            key={item.id}
            onClick={() => (createViagem(item))}
          >
            <span style={styles.titleStyle}>{`${item.codigo} - ${item.nome}`}</span>
          </div>
        ) : null
      ))}

    </RBody>
  );
}

export default IniciarViagemPage;
