import React from 'react';
import { Paper, Skeleton } from '@mui/material';

const RSkeletonTable = () => {
    return (
        <Paper style={{
            backgroundColor: '#0A1126', 
            width: '95%', 
            overflow: 'hidden', 
            borderRadius: '20px', 
            boxShadow: '0px 4px 16px -2px rgba(0,0,0,0.5)',
            position: 'relative' // Importante para a posição do overlay de animação
        }}>
            <Skeleton variant="rectangular" width="100%" height={400} animation={false} style={{ backgroundColor: '#182038' }} />
            <div className="light-strip"></div>
        </Paper>
    );
};

export default RSkeletonTable;
