import React from 'react';
import RBody from '../../components/RBody';
import { useStyles } from './styles';
import RRoutesTable from '../../components/RRoutesTable';

function Mercadorias() {
    const styles = useStyles();

    const routeData = [
        { label: "Produtos", size: 150, iconName: "MdShelves", iconColor: '#DBF22E', textColor: '#eee', backgroundColor: '#0A1126', path: '/mercadorias/produtos' },
        { label: "Compras", size: 150, iconName: "MdAddShoppingCart", iconColor: '#DBF22E', textColor: '#eee', backgroundColor: '#0A1126', path: '/mercadorias/compras' },
        { label: "Fornecedor", size: 150, iconName: "MdPerson4", iconColor: '#DBF22E', textColor: '#eee', backgroundColor: '#0A1126', path: '/mercadorias/compras' },
    ];

    return (
        <RBody
            title="Mercadorias"
            titleColor='#eee'
        >
            <RRoutesTable routes={routeData} />
        </RBody>
    );
}

export default Mercadorias;
