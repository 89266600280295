export function useStyles() {

    const styles = {
       table: {
        display: 'flex',
        justifyContent: 'center', 
        alignItems: 'center', 
      }
    }
  
    return styles;
  }