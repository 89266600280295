import React from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Divider, IconButton, Box } from '@mui/material';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import SignpostOutlinedIcon from '@mui/icons-material/SignpostOutlined';
import ShoppingBasketOutlinedIcon from '@mui/icons-material/ShoppingBasketOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import Logo from '../../images/logoHeader.svg';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import { Link } from 'react-router-dom';
import { toggleSidebar } from './sidebarActions';
import { useSelector, useDispatch } from 'react-redux';
import { useStyles } from './styles';


function RSidebar() {
    const isOpen = useSelector(state => state.sidebar.isOpen);
    const dispatch = useDispatch();
    const styles = useStyles();

    const toggleDrawer = async () => {
        dispatch(toggleSidebar(!isOpen));
    };
    
    const handleLogout = async () => {
        localStorage.removeItem('token');
        window.location.href = '/login';
    };

    const menuItems = [
        { text: 'Clientes', icon: <PersonOutlineIcon />, path: '/clientes' },
        { text: 'Financeiro', icon: <PaymentsOutlinedIcon />, path: '/financeiro' },
        { text: 'Notas', icon: <ReceiptLongOutlinedIcon />, path: '/notas' },
        { text: 'Rotas', icon: <SignpostOutlinedIcon />, path: '/rotas' },
        { text: 'Mercadorias', icon: <ShoppingBasketOutlinedIcon />, path: '/mercadorias' },
        { text: 'Configurações', icon: <SettingsOutlinedIcon />, path: '/configuracoes' },
        { text: 'Sair', icon: <LogoutOutlinedIcon />, onClick: handleLogout },
    ];

    return (
        <Drawer
            variant="permanent"
            sx={{ '& .MuiDrawer-paper': styles.paper }}
            open={true}
        >
            <IconButton onClick={toggleDrawer}
                style={{
                    ...styles.iconMenu,
                    padding: '8px',
                    borderRadius: '0%',
                    '&:hover': {
                        bgcolor: 'rgba(0, 0, 0, 0.04)',
                    }
                }}>
                <MenuOutlinedIcon />
            </IconButton>

            <Link to={'/dashboard'} style={{ textDecoration: 'none', color: 'inherit' }}>
                <img src={Logo} alt="Logo" style={styles.logo} />
            </Link>

            <Divider sx={styles.mainDivider} />
            <List style={{
                width: '100%',
                maxHeight: 'calc(100vh - 64px)',
                overflowY: 'auto',
                overflowX: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                '&::-webkit-scrollbar': {
                    width: '1px',
                    backgroundColor: 'transparent',
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#888',
                },
                scrollbarWidth: 'thin',
                scrollbarColor: '#888 transparent',
            }}>
                {menuItems.map((item, index) => (
                    <Link to={item.path} key={index} style={{ textDecoration: 'none', color: 'inherit' }}>
                        <Box key={index}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                width: '100%',
                            }} 
                            onClick={item.onClick}
                            >
                            <ListItem
                                button
                                sx={{
                                    justifyContent: 'center',
                                    width: '100%',
                                    '&:hover': {
                                        backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                    },
                                    backgroundColor: 'transparent',
                                }}>
                                <Box sx={{ width: '220px', display: 'flex', justifyContent: 'center' }}>
                                    <ListItemIcon sx={styles.icon}>{item.icon}</ListItemIcon>
                                    <ListItemText primary={item.text} sx={styles.text} primaryTypographyProps={{ fontWeight: 'bold', fontSize: '20px' }} />
                                </Box>
                            </ListItem>
                            <Divider sx={styles.divider} />
                        </Box>
                    </Link>
                ))}

            </List>
        </Drawer>
    );
}

export default RSidebar;