import React, { useState, useEffect } from 'react';
import RBody from '../../../../components/RBody';
import RForm from '../../../../components/RForm';
import { useStyles } from './styles';
import { useAlert } from '../../../../services/apiService';

const AdicionarUsuariosPage = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    const { callApi } = useAlert();

    const handleCreate = async (formData) => {
        try {
            const result = await callApi({
                path: '/user/store',
                method: 'POST',
                body: formData,
                need_token: true,
                alerta: true
            });
            if (result.success) {
                if (result.data) {
                    // EM BREVE
                } else {
                    console.error('Erro ao criar cliente.');
                }
            } else {
                console.error('Erro ao criar cliente:', result.message);
            }
        } catch (error) {
            console.error('Erro ao criar cliente:', error);
        }
    };

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
            setWindowHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, []);

    const styles = useStyles();

    const clienteFormFields = [
        {
            placeholder: 'Nome',
            type: 'text',
            width: 45,
            required: true
        },
        {
            placeholder: 'Telefone',
            type: 'text',
            width: 45,
            required: false
        },
        {
            placeholder: 'Email',
            type: 'text',
            width: 45,
            required: true
        },
        {
            placeholder: 'Senha',
            type: 'text',
            width: 45,
            required: true,
            hidden: true
        },
    ];

    return (
        <RBody title="Adicionar Usuário" titleColor='#eee'>

            <RForm fields={clienteFormFields} onSubmit={handleCreate} />

        </RBody>
    );
}

export default AdicionarUsuariosPage;
