import React, { useState, useEffect } from 'react';
import RBody from '../../../../components/RBody';
import RForm from '../../../../components/RForm';
import { useStyles } from './styles';
import { useAlert } from '../../../../services/apiService';

const AdicionarRotasPage = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [clientes, setClientes] = useState([]);

    const { callApi } = useAlert();

    const handleCreate = async (formData) => {
        try {
            const result = await callApi({
                path: '/rota/store',
                method: 'POST',
                body: formData,
                need_token: true,
                alerta: true
            });
            if (result.success) {
                if (result.data) {
                    // EM BREVE
                } else {
                    console.error('Erro ao criar rota.');
                }
            } else {
                console.error('Erro ao criar rota:', result.message);
            }
        } catch (error) {
            console.error('Erro ao criar rota:', error);
        }
    };


    const handleClientes = async () => {
        try {
            const result = await callApi({
                path: '/clientes',
                method: 'GET',
                body: null,
                need_token: true
            });
            if (result.success) {
                if (result.data) {
                    setClientes(result.data.reverse());
                } else {
                    console.error('Dados de clientes não encontrados na resposta da API.');
                }
            } else {
                console.error('Erro ao obter clientes:', result.message);
            }
        } catch (error) {
            console.error('Erro ao obter clientes:', error);
        }
    };

    useEffect(() => {
        handleClientes();

        const handleResize = () => {
            setWindowWidth(window.innerWidth);
            setWindowHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, []);

    const clientesLista = clientes.map(item => {
        if (item.ativo) {
            return {
                value: item._id ?? '',
                label: item.nome ?? ''
            };
        } else {
            return null;
        }
    });


    const styles = useStyles();

    const rotaFormFields = [
        {
            placeholder: 'Nome',
            type: 'text',
            width: 45,
            required: true
        },
        {
            id: 'clientes',
            placeholder: 'Clientes',
            type: 'select',
            options: clientesLista,
            width: 45,
            required: false
        },
    ];

    return (
        <RBody title="Adicionar Rota" titleColor='#eee'>

            <RForm fields={rotaFormFields} onSubmit={handleCreate} />

        </RBody>
    );
}

export default AdicionarRotasPage;
