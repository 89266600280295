import React from 'react';
import RBody from '../../../components/RBody';
import { useStyles } from './styles';
import RRoutesTable from '../../../components/RRoutesTable';

function Rotas() {
    const styles = useStyles();

    const routeData = [
        { label: "Buscar", size: 150, iconName: "MdAccountCircle", iconColor: '#DBF22E', textColor: '#eee', backgroundColor: '#0A1126', path: '/rotas/buscar' },
        { label: "Adicionar", size: 150, iconName: "MdAccountCircle", iconColor: '#DBF22E', textColor: '#eee', backgroundColor: '#0A1126', path: '/rotas/adicionar' },
        { label: "Listar", size: 150, iconName: "MdFormatListBulleted", iconColor: '#DBF22E', textColor: '#eee', backgroundColor: '#0A1126', path: '/rotas/listar' },
    ];

    return (
        <RBody
            title="Rotas"
            titleColor='#eee'
        >
            <RRoutesTable routes={routeData} />
        </RBody>
    );
}

export default Rotas;
