import React, { useState, useEffect } from 'react';
import loginImage from '../../images/login_image.svg';
import { MdAccountCircle, MdLock, MdVisibility, MdVisibilityOff, MdHexagon } from 'react-icons/md';
import { TextField, Button, IconButton, InputAdornment, CircularProgress, Backdrop } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SiInstagram } from "react-icons/si";
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useAlert } from '../../services/apiService';

const useStyles = makeStyles(theme => ({
  textField: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'transparent', // Mantém transparente sempre
      },
      '&:hover fieldset': {
        borderColor: 'transparent', // Mantém transparente ao passar o mouse
      },
      '&.Mui-focused fieldset': {
        borderColor: 'transparent', // Mantém transparente ao focar
      }
    },
    '& input:focus, & textarea:focus, & select:focus': {
      boxShadow: 'none',
      border: '0 none',
      outline: '0'
    }
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundColor: '#0A1126',
  },
}));

const RCheckbox = withStyles({
  root: {
    color: '#eee', // Cor quando desmarcado
    '&$checked': {
      color: '#DBF22E', // Cor quando marcado
    },
  },
  checked: {} // Necessário para aplicar a cor personalizada quando marcado
})(Checkbox);

function LoginPage() {
  const { callApi } = useAlert();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [imageLoaded, setImageLoaded] = useState(false);
  const classes = useStyles();
  const [checked, setChecked] = useState(false);
  const [formVisible, setFormVisible] = useState(false);

  const handleImageLoaded = () => {
    setImageLoaded(true);
    setFormVisible(true);
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const emailStyle = {
    marginTop: windowHeight < 900 ? windowWidth / 4.5 : windowHeight / 10,
    marginBottom: windowHeight / 60,
    width: windowWidth > 639 ? windowWidth / 2.25 : windowHeight / 2.75
  };

  const senhaStyle = {
    marginTop: windowHeight / 60,
    marginBottom: windowHeight / 600,
    width: windowWidth > 639 ? windowWidth / 2.25 : windowHeight / 2.75,
  };

  const inputProps = {
    style: {
      fontSize: windowHeight / 30,
      lineHeight: windowHeight < 640 ? windowHeight / 250 : windowHeight / 650,
      overflow: 'hidden'
    }
  }
  const iconInputProps = {
    fontSize: windowHeight / 30,
    color: '#eee',
    marginLeft: '10px'
  }

  const buttonProps = {
    backgroundColor: '#2ABFB0',
    color: '#0A1126',
    borderRadius: '80px',
    boxShadow: '0px 4px 16px -2px rgba(0,0,0,2)',
    height: windowHeight / 20,
    width: '85%'
  }

  const buttonTextProps = {
    fontSize: windowHeight < windowWidth ? windowHeight / 35 : windowHeight / 35,
  }

  const instagramTextProps = {
    color: '#eee',
    fontSize: windowWidth > windowHeight ? windowHeight / 15 + 'px' : windowWidth / 15 + 'px'
  }

  const instagramIconProps = {
    color: '#DBF22E',
    fontSize: windowWidth > windowHeight ? windowHeight / 15 + 'px' : windowWidth / 15 + 'px',
    paddingTop: '25%'
  }

  const ahrefProps = {
    textDecoration: 'none',
    marginTop: windowHeight < 900 ? '0px' : windowWidth / 25 + 'px'
  }

  let titleProps = {};

  if (!(windowWidth < 640 || windowWidth < windowHeight)) {
    titleProps = {
      fontSize: windowHeight / 10 + 'px',
      position: 'absolute',
      top: '-1%',
      color: '#eee',
      left: '75%', // Posiciona o canto esquerdo do título em 75% da largura da tela
      transform: 'translateX(-50%)' // Move o título para a esquerda pela metade de sua própria largura
    }

  } else {
    titleProps = {
      fontSize: windowHeight / 12 + 'px',
      position: 'absolute',
      bottom: windowHeight / 2.85,
      color: '#eee',
    }
  }

  const bodyProps = {
    backgroundColor: '#0A1126',
    height: '100vh',
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    flexDirection: (!(windowWidth < 640 || windowWidth < windowHeight)) ? 'row' : 'column',
  }

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async () => {
    try {
      const body = {
        email,
        password,
        maintain_token: checked,
      };

      const result = await callApi({
        path: '/login',
        method: 'POST',
        body: body,
        need_token: false,
        alerta: true
      });

      // Salva o token no localStorage
      localStorage.setItem('token', result.token);

      // Redirecionar para /dashboard
      window.location.href = '/dashboard'; // ou use useHistory de 'react-router-dom' se estiver usando React Router
    } catch (error) {
      console.error('Login Failed:', error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Impede o comportamento padrão do Enter
      handleLogin(); // Chama a função de login
    }
  };

  return (
    <div style={bodyProps}>
      <Backdrop className={classes.backdrop} open={!formVisible}>
        <CircularProgress color="secondary" style={{ color: '#2ABFB0' }} />
      </Backdrop>
      <img src={loginImage} alt="Login" style={{ display: 'none'}} onLoad={() => {
        setImageLoaded(true);
        setFormVisible(true);
      }} />

      {formVisible && (
        <>
          <div>
            {!(windowWidth < 640 || windowWidth < windowHeight) ? (
              <img src={loginImage}
                alt="Login"
                style={{
                  height: '100vh',
                  maxWidth: '50vw',
                  objectFit: 'cover'
                }}
                onLoad={() => setImageLoaded(true)} />
            ) : (
              <img src={loginImage}
                alt="Login"
                style={{
                  width: '100%',
                  maxHeight: '50vh',
                  objectFit: 'cover'
                }}
                onLoad={() => setImageLoaded(true)} />
            )}
          </div>

          <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            color: '#eee',
            margin: 'auto'
          }}>
            <h1 style={titleProps}>Login</h1>

            <TextField
              className={classes.textField}
              placeholder="E-mail"
              value={email}
              onKeyPress={handleKeyPress}
              inputProps={inputProps}
              onChange={(e) => setEmail(e.target.value)}
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <MdAccountCircle style={iconInputProps} />
                  </InputAdornment>
                ),
                style: { color: '#eee', borderRadius: '80px', outline: '1px solid #eee', backgroundColor: 'transparent', borderBottom: 'none', height: (windowHeight / 14 + 'px') }
              }}
              InputLabelProps={{
                style: { color: '#eee' }
              }}
              style={emailStyle}
            />

            <TextField
              className={classes.textField}
              type={showPassword ? 'text' : 'password'}
              placeholder="Senha"
              onKeyPress={handleKeyPress}
              value={password}
              inputProps={inputProps}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <MdLock style={iconInputProps} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handlePasswordVisibility}>
                      {showPassword ? <MdVisibilityOff style={iconInputProps} /> : <MdVisibility style={iconInputProps} />}
                    </IconButton>
                  </InputAdornment>
                ),
                style: {
                  borderRadius: '80px',
                  color: '#eee',
                  outline: 'solid 1px #eee',
                  borderBottom: 'none',
                  height: (windowHeight / 14 + 'px')
                },
              }}
              InputLabelProps={{
                style: { color: '#eee' },
              }}
              style={senhaStyle}
            />

            <FormControlLabel
              style={{
                marginBottom: '15px',
                alignSelf: 'flex-start', // Alinha o controle à esquerda
                marginLeft: 0, // Remove qualquer margem esquerda, se necessário
                width: '100%'
              }}
              control={
                <RCheckbox
                  checked={checked}
                  onChange={handleChange}
                  name="checkedYellow"
                />
              }
              label="Manter conectado"
            />

            <Button variant="contained" style={buttonProps} onClick={handleLogin}>
              <span style={buttonTextProps}>ENTRAR</span>
            </Button>

            <a href='https://instagram.com/easyrota' style={ahrefProps} target='_blank' rel='noopener noreferrer'>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton>
                  <SiInstagram style={instagramIconProps} />
                </IconButton>
                <span style={instagramTextProps}>easyrota</span>
              </div>
            </a>
          </div>
        </>
      )}
    </div >
  );
}

export default LoginPage;