import React, { useState, useEffect } from 'react';
import RBody from '../../../components/RBody';
import RForm from '../../../components/RForm';
import { useStyles } from './styles';
import { useAlert } from '../../../services/apiService';

const AdicionarClientesPage = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    const { callApi } = useAlert();

    const handleCreate = async (formData) => {
        try {
            const result = await callApi({
                path: '/cliente/store',
                method: 'POST',
                body: formData,
                need_token: true,
                alerta: true
            });
            if (result.success) {
                if (result.data) {
                    // EM BREVE
                } else {
                    console.error('Erro ao criar cliente.');
                }
            } else {
                console.error('Erro ao criar cliente:', result.message);
            }
        } catch (error) {
            console.error('Erro ao criar cliente:', error);
        }
    };

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
            setWindowHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, []);

    const styles = useStyles();

    const clienteFormFields = [
        {
            placeholder: 'Nome',
            type: 'text',
            width: 45,
            required: true
        },
        {
            placeholder: 'Telefone',
            type: 'text',
            width: 45,
            required: false
        },
        {
            placeholder: 'Estado',
            type: 'text',
            width: 45,
            required: true
        },
        {
            placeholder: 'Cidade',
            type: 'text',
            width: 45,
            required: true
        },
        {
            placeholder: 'Bairro',
            type: 'text',
            width: 45,
            required: true
        },
        {
            placeholder: 'Rua',
            type: 'text',
            width: 45,
            required: true
        },
        {
            placeholder: 'Número',
            type: 'text',
            width: 45,
            required: true
        },
        {
            placeholder: 'Complemento',
            type: 'text',
            width: 45,
            required: false,
            value: 'teste'
        },
        // {
        //     placeholder: 'Rotas',
        //     type: 'dropdown',
        //     options: ['Rota 1', 'Rota 2', 'Rota 3', 'Rota 4', 'Rota 5'],
        //     width: 45,
        //     required: false
        // },
    ];

    return (
        <RBody title="Adicionar Cliente" titleColor='#eee'>

            <RForm fields={clienteFormFields} onSubmit={handleCreate} />

        </RBody>
    );
}

export default AdicionarClientesPage;
