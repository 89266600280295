import React, { useState } from 'react';
import { Select, InputNumber, Row, Col } from 'antd';

const { Option } = Select;

function QuantitySelect() {
  const [selectedItems, setSelectedItems] = useState([]);

  const handleChange = (value) => {
    // Atualizar a lista de itens com as quantidades preservadas
    setSelectedItems(value);
  };

  const handleQuantityChange = (item, quantity) => {
    // Atualizar a quantidade do item específico
    const updatedItems = selectedItems.map(i => {
      if (i.key === item.key) {
        return { ...i, quantity };
      }
      return i;
    });
    setSelectedItems(updatedItems);
  };

  // Opções de exemplo
  const options = [
    { key: 'apple', label: 'Apple' },
    { key: 'orange', label: 'Orange' },
    { key: 'banana', label: 'Banana' }
  ];

  return (
    <Select
      mode="multiple"
      style={{ width: '100%' }}
      placeholder="Select items"
      value={selectedItems}
      labelInValue
      onChange={handleChange}
      dropdownRender={menu => (
        <div>
          {menu}
          <Row style={{ padding: 8 }}>
            {selectedItems.map(item => (
              <Col span={24} key={item.key} style={{ marginBottom: 8 }}>
                <Row gutter={8} align="middle">
                  <Col flex="auto">{item.label}</Col>
                  <Col flex="none">
                    <InputNumber
                      min={0}
                      value={item.quantity || 0}
                      onChange={(q) => handleQuantityChange(item, q)}
                    />
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
        </div>
      )}
    >
      {options.map(option => (
        <Option key={option.key} value={option.key}>
          {option.label}
        </Option>
      ))}
    </Select>
  );
}

export default QuantitySelect;
