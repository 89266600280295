import { useState, useEffect } from 'react';

export function useStyles() {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const styles = {
    containerStyle: {
      padding: '20px',
      backgroundColor: '#0A1126',
      borderRadius: '50px',
      textAlign: 'center',
      boxSizing: 'border-box',
      margin: '10px',
      width: windowWidth * 0.275,
      height: windowWidth / 6,
      minWidth: 275,
      minHeight: 275,
      position: 'relative'
    },

    titleStyle: {
      color: '#eee',
      fontSize: '22px',
      width: '100%',
      padding: '10px 0',
      position: 'sticky',
      top: 0,
      backgroundColor: '#0A1126',
      zIndex: 10,
      borderBottom: '1px solid #1C306F'
    },

    listStyle: {
      overflowY: 'auto',
      margin: 0,
      padding: '5px 20px 20px 20px',
      listStyle: 'none',
      scrollbarWidth: 'thin',
      scrollbarColor: '#eeeeee88 #eeeeee15',
      maxHeight: 'calc(75% - 80px)'
    },

    webkitScrollbarStyles: {
      '::-webkit-scrollbar': {
        width: '4px'
      },
      '::-webkit-scrollbar-track': {
        backgroundColor: '#eeeeee26'
      },
      '::-webkit-scrollbar-thumb': {
        backgroundColor: '#eeeeeeE6',
        borderRadius: '2px'
      }
    },

    dividerStyle: {
      backgroundColor: '#1C306F',
      height: '1px',
      width: '100%',
      margin: '10px 0'
    }
  }

  return styles;
}