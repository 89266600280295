import React, { Suspense, useState, useEffect } from 'react';
import RBody from '../../../../components/RBody';
import { useStyles } from './styles';
import { useAlert } from '../../../../services/apiService';
import RSkeletonTable from '../../../../components/RTable/RSkeletonTable';
const RTable = React.lazy(() => import('../../../../components/RTable'));

const ListarViagensPage = () => {
  const { callApi } = useAlert();
  const styles = useStyles();
  const [viagens, setViagens] = useState([]);

  useEffect(() => {
    handleViagens();
  }, []);

  const handleViagens = async () => {
    try {
      const result = await callApi({
        path: '/viagens',
        method: 'GET',
        body: null,
        need_token: true
      });
      if (result.success) {
        if (result.data) {
          setViagens(result.data.reverse());
        } else {
          console.error('Dados de viagens não encontrados na resposta da API.');
        }
      } else {
        console.error('Erro ao obter viagens:', result.message);
      }
    } catch (error) {
      console.error('Erro ao obter viagens:', error);
    }
  };


  const columns = [
    { id: 'id', label: 'ID', minWidth: 50, type: 'number' },
    { id: 'rota', label: 'Rota', minWidth: 170, type: 'text' },
    { id: 'data_inicio', label: 'Data Início', minWidth: 100, type: 'date' },
    { id: 'data_fim', label: 'Data Fim', minWidth: 40, type: 'date' },
    { id: 'concluido', label: 'Concluído', minWidth: 40, type: 'text' },

    { id: 'ativo', label: 'Ativo', minWidth: 50, type: 'text' },
  ];

  const actionButtons = [
    { text: 'Inativar', onClick: () => console.log('Ação 1 executada') },
    { text: 'Adicionar em rota', onClick: () => console.log('Ação 2 executada') },
  ];

  const rows = viagens.map(item => {
    const todosConcluidos = item.clientes_concluidos.every(c => c.concluido);

    return {
      id: item.codigo ?? '',
      rota: item.rota.nome ?? '',
      data_inicio: item.data_inicio ? new Date(item.data_inicio).toLocaleDateString('pt-BR') : '',
      data_fim: item.data_fim && todosConcluidos ? new Date(item.data_fim).toLocaleDateString('pt-BR') : 'Não finalizado',
      concluido: todosConcluidos ? 'Sim' : 'Não',

      ativo: item.ativo ? 'Ativo' : 'Inativo'
    };
  });

  return (
    <RBody
      title="Listar Viagens"
      titleColor='#eee'
    >
      <div style={styles.table}>
        <Suspense fallback={<RSkeletonTable />}>
          <RTable data={rows} columns={columns} actionButtons={actionButtons} columnToRedirect={'id'} />
        </Suspense>
      </div>
    </RBody>
  );
}

export default ListarViagensPage;
