export function useFormFields(rota, clientes) {

  let clientesRota = null;
  let clientesLista = null;

  if (rota.clientes) {
    clientesRota = rota.clientes.map(item => {
      if (item.ativo) {
        return {
          value: item._id ?? '',
          label: item.nome ?? ''
        };
      } else {
        return null;
      }
    });
  }

  if (clientes) {
    clientesLista = clientes.map(item => {
      if (item.ativo) {
        return {
          value: item._id ?? '',
          label: item.nome ?? ''
        };
      } else {
        return null;
      }
    });
  }

  const rotaFormFields = [
    {
      placeholder: 'Nome',
      type: 'text',
      width: 45,
      required: true,
      value: rota.nome
    },
    {
      id: 'clientes',
      placeholder: 'Clientes',
      type: 'select',
      options: clientesLista,
      width: 45,
      required: false,
      value: clientesRota
    },
    {
      id: 'Ativo',
      placeholder: 'Ativo',
      options: [
        { value: true, label: 'Ativo' },
        { value: false, label: 'Inativo' }
      ],
      type: 'dropdown',
      width: 45,
      required: true,
      value: rota.ativo
    },
  ];

  return rotaFormFields;
}