import React, { useState, useEffect } from 'react';
import RBody from '../../../../components/RBody';
import RForm from '../../../../components/RForm';
import { useStyles } from './styles';
import { useAlert } from '../../../../services/apiService';
import { useParams } from 'react-router-dom';
import { useFormFields } from './fields';

const CadastroRotasPage = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [rota, setRota] = useState({});
    const [loading, setLoading] = useState(true);
    const [clientes, setClientes] = useState([]);

    const { id } = useParams();

    const { callApi } = useAlert();

    const handleUpdate = async (formData) => {
        try {
            const result = await callApi({
                path: `/rotas/${id}`,
                method: 'PUT',
                body: formData,
                need_token: true,
                alerta: true
            });
            if (result.success) {
                if (result.data) {
                    setRota(result.data.reverse());
                } else {
                    console.error('Erro ao criar rota.');
                }
            } else {
                console.error('Erro ao criar rota:', result.message);
            }
        } catch (error) {
            console.error('Erro ao criar rota:', error);
        }
    };

    const handleClientes = async () => {
        try {
            const result = await callApi({
                path: '/clientes',
                method: 'GET',
                body: null,
                need_token: true
            });
            if (result.success) {
                if (result.data) {
                    setClientes(result.data);
                } else {
                    console.error('Dados de clientes não encontrados na resposta da API.');
                }
            } else {
                console.error('Erro ao obter clientes:', result.message);
            }
        } catch (error) {
            console.error('Erro ao obter clientes:', error);
        }
    };


    const getRota = async () => {
        try {
            const result = await callApi({
                path: `/rotas/${id}`,
                method: 'GET',
                need_token: true
            });
            if (result.success) {
                if (result.data) {
                    setRota(result.data);
                    setLoading(false);
                } else {
                    console.error('Erro ao buscar rota.');
                    setLoading(false);
                }
            } else {
                console.error('Erro ao buscar rota:', result.message);
                setLoading(false);
            }
        } catch (error) {
            console.error('Erro ao buscar rota:', error);
            setLoading(false);
        }
    };

    useEffect(() => {

        handleClientes();
        getRota();

        const handleResize = () => {
            setWindowWidth(window.innerWidth);
            setWindowHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, []);

    const styles = useStyles();
    const rotaFormFields = useFormFields(rota, clientes);

    return (
        <RBody title={rota.nome || 'Carregando...'} titleColor='#eee'>
            {loading ? (
                <div>Loading...</div>
            ) : (
                <RForm fields={rotaFormFields} onSubmit={handleUpdate} />
            )}
        </RBody>
    );
}

export default CadastroRotasPage;
