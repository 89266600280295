export function useStyles() {

    const styles = {
        container: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            gap: '20px',
            padding: '20px',
            transition: 'all 700ms ease',
        },
        item: {
            display: 'flex',
            justifyContent: 'center',
            transition: 'all 700ms ease'
        }
    };

    return styles;
}