import React from 'react';
import RBody from '../../../components/RBody';
import { useStyles } from './styles';
import RRoutesTable from '../../../components/RRoutesTable';

function Configuracoes() {
    const styles = useStyles();

    const routeData = [
        { label: "Buscar", size: 150, iconName: "MdManageSearch", iconColor: '#DBF22E', textColor: '#eee', backgroundColor: '#0A1126', path: '/configuracoes/usuarios/buscar' },
        { label: "Adicionar", size: 150, iconName: "MdAccountCircle", iconColor: '#DBF22E', textColor: '#eee', backgroundColor: '#0A1126', path: '/configuracoes/usuarios/adicionar' },
        { label: "Listar", size: 150, iconName: "MdFormatListBulleted", iconColor: '#DBF22E', textColor: '#eee', backgroundColor: '#0A1126', path: '/configuracoes/usuarios/listar' },
    ];

    return (
        <RBody
            title="Configurações"
            titleColor='#eee'
        >
            <RRoutesTable routes={routeData} />
        </RBody>
    );
}

export default Configuracoes;
