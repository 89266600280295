export function useStyles(route) {

    const styles = {
        button: {
            backgroundColor: route.backgroundColor,
            borderRadius: '20px',
            width: route.size,
            height: route.size,
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            userSelect: 'none',
            overflow: 'hidden',
            padding: '5px',
            boxSizing: 'border-box',
            transition: 'all 700ms ease',
            boxShadow: '0px 4px 16px -2px rgba(0,0,0,0.5)',
        },
        text: {
            color: route.textColor,
            fontWeight: 'bold',
            fontSize: '1.5em',
            overflow: 'hidden',
            textOverflow: 'clip',
            whiteSpace: 'nowrap',
            marginTop: '0px',
            marginBottom: '0px'
        },
        icon: {
            color: route.iconColor,
            fontSize: '40px',
            margin: '0px'
        }
    };

    return styles;
}