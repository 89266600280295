// PrivateRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ element: Element, ...props }) => {
    const token = localStorage.getItem('token');

    // Retorna diretamente o elemento ou redireciona
    return token ? <Element {...props} /> : <Navigate to="/login" replace />;
};

export default PrivateRoute;
