import axios from 'axios';
import React, { createContext, useContext, useState } from 'react';
import RAlert from '../components/RAlert'; // Verifique se o caminho está correto

export const API_BASE_URL = 'https://easyrota.com.br/api';
const AlertContext = createContext();

export const useAlert = () => useContext(AlertContext);

export const AlertProvider = ({ children }) => {
    const [alerts, setAlerts] = useState([]);

    const addAlert = (title, message, success) => {
        setAlerts(currentAlerts => [...currentAlerts, { title, message, success, id: Math.random() }]);
    };

    const removeAlert = (id) => {
        setAlerts(currentAlerts => currentAlerts.filter(alert => alert.id !== id));
    };

    const callApi = async ({ path, method = 'GET', body = null, params = {}, need_token = true, alerta = false }) => {
        const url = new URL(API_BASE_URL + path);
        Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
    
        const headers = {
            'Content-Type': 'application/json'
        };
    
        if (need_token) {
            const token = localStorage.getItem('token');
            if (!token) {
                if (alerta) {
                    addAlert('Erro', 'Token não encontrado, redirecionando para login.', false);
                }
                window.location.href = '/login';
                return;
            }
            headers['Authorization'] = `Bearer ${token}`;
        }
    
        try {
            const response = await axios({
                method,
                url: url.href,
                data: body,
                headers
            });
    
            if (alerta) {
                addAlert('Sucesso', 'Operação realizada com êxito!', true);
            }
            return response.data;
        } catch (error) {
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                localStorage.removeItem('token');
                window.location.href = '/login';
                return;
            }
            if (error.response && error.response.data && error.response.data.errors && alerta) {
                const messages = error.response.data.errors.map(err => `${err.msg}`).join('\n');
                addAlert('Erro', messages, false);
            } else if (alerta) {
                addAlert('Erro', error.response?.data?.message || 'Algo de errado aconteceu, contate o suporte!', false);
            }
            throw error;
        }
    };
    

    return (
        <AlertContext.Provider value={{ callApi, addAlert, removeAlert }}>
            {children}
            {alerts.map((alert) => (
                <RAlert
                    key={alert.id}
                    title={alert.title}
                    message={alert.message}
                    success={alert.success}
                    onClose={() => removeAlert(alert.id)}
                />
            ))}
        </AlertContext.Provider>
    );
};
