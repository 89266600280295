export function useStyles() {

  const styles = {

    formContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '20px'
    },
    submitButton: {
      marginTop: '20px',
      width: '200px',
    },
    button: {
      fontWeight: 'bold',
      fontSize: '16px',
      color: '#0A1126',
      backgroundColor: '#2ABFB0',
      width: '30%',
      padding: '10px 0',
      textAlign: 'center',
      cursor: 'pointer',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      borderRadius: '4px',
      userSelect: 'none'
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    input: {
      backgroundColor: 'rgba(200,200,200,0.01)',
      borderRadius: '20px',
      marginRight: '10%',
      marginLeft: '10%',
      margin: '10px',
      minWidth: '250px',

      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          border: 'none',
        },
        '&:hover fieldset': {
          border: 'none',
        },
        '&.Mui-focused fieldset': {
          border: 'none',
        },
      },
      '& .MuiInputBase-input': {
        color: '#eee', 
        caretColor: '#eee' 
      },
      '& .MuiInputBase-input::placeholder': {
        color: '#eee',
        opacity: 0.7,
      },
      '& .MuiSelect-select': {
        color: '#eee', 
      },
      '& .MuiMenuItem-root': {
        color: '#eee', 
      },
      '& .MuiSelect-icon': {
        color: '#eee', 
      }
    }
  }

  return styles;
}