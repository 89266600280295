import React, { useState, useEffect, useRef } from 'react';
import { Snackbar, Slide } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

function SlideTransition(props) {
    return <Slide {...props} direction="down" />;
}

function RAlert({ title, message, success, onClose }) {
    const [open, setOpen] = useState(true);
    const timerRef = useRef(null);

    useEffect(() => {
        timerRef.current = setTimeout(() => {
            setOpen(false);
        }, 8000);

        return () => {
            clearTimeout(timerRef.current);
        };
    }, []);

    useEffect(() => {
        if (!open && onClose) {
            onClose(); // Invocar o callback de fechamento ao fechar o alerta
        }
    }, [open, onClose]);

    return (
        <Snackbar
            open={open}
            onClose={() => setOpen(false)}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            TransitionComponent={SlideTransition}
            onMouseEnter={() => clearTimeout(timerRef.current)}
            onMouseLeave={() => {
                timerRef.current = setTimeout(() => {
                    setOpen(false);
                }, 8000);
            }}
            style={{ maxWidth: '350px' }}
        >
            <Alert
                onClose={() => setOpen(false)}
                severity={success ? 'success' : 'error'}
                elevation={6}
                variant="filled"
                style={{
                    whiteSpace: 'pre-line',
                    wordWrap: 'break-word',
                    backgroundColor: success ? '#4caf50' : undefined
                }}
            >
                <div style={{ fontSize: '1.2em', fontWeight: 'bold' }}>{title}</div>
                <div style={{ fontSize: '0.9em' }}>{message}</div>
            </Alert>
        </Snackbar>
    );
}

export default RAlert;
