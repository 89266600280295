import React, { Suspense, useState, useEffect } from 'react';
import RBody from '../../../../components/RBody';
import { useStyles } from './styles';
import { useAlert } from '../../../../services/apiService';
import RSkeletonTable from '../../../../components/RTable/RSkeletonTable';
const RTable = React.lazy(() => import('../../../../components/RTable'));

const BuscarUsuariosPage = () => {
  const { callApi } = useAlert();
  const styles = useStyles();
  const [usuarios, setUsuarios] = useState([]);

  useEffect(() => {
    handleUsuarios();
  }, []);

  const handleUsuarios = async () => {
    try {
      const result = await callApi({
        path: '/users',
        method: 'GET',
        body: null,
        need_token: true
      });
      if (result.success) {
        if (result.data) {
          setUsuarios(result.data.reverse());
        } else {
          console.error('Dados de usuários não encontrados na resposta da API.');
        }
      } else {
        console.error('Erro ao obter usuários:', result.message);
      }
    } catch (error) {
      console.error('Erro ao obter usuários:', error);
    }
  };


  const columns = [
    { id: 'id', label: 'ID', minWidth: 50, type: 'number' },
    { id: 'nome', label: 'Nome', minWidth: 170, type: 'text' },
    { id: 'telefone', label: 'Telefone', minWidth: 100, type: 'text' },
    { id: 'email', label: 'E-Mail', minWidth: 100, type: 'text' },

    { id: 'ativo', label: 'Ativo', minWidth: 50, type: 'text' },
  ];

  const rows = usuarios.map(item => ({
    id: item.codigo ?? '',
    nome: item.nome ?? '',
    telefone: item.telefone ?? '',
    email: item.email ?? '',
    
    ativo: item.ativo ? 'Ativo' : 'Inativo'
  }));

  return (
    <RBody
      title="Buscar Usuários"
      titleColor='#eee'
    >
      <div style={styles.table}>
        <Suspense fallback={<RSkeletonTable />}>
          <RTable data={rows} columns={columns} columnToRedirect={'nome'} />
        </Suspense>
      </div>
    </RBody>
  );
}

export default BuscarUsuariosPage;
