import { useSelector } from 'react-redux';

export function useStyles() {

    const isOpen = useSelector(state => state.sidebar.isOpen);

    const styles = {
        paper: {
            width: isOpen ? 300 : 50,
            transition: 'width 700ms ease',
            bgcolor: '#0A1126',
            borderRight: 'none',
            borderRadius: '0',
            position: 'absolute',
            zIndex: 1,
            overflowX: 'hidden',
            boxShadow: '4px 0 16px -2px rgba(0,0,0,0.5)',
        },
        logo: {
            padding: !isOpen ? '10px 10px 20px 10px' : '10px 20px 20px 20px',
            width: !isOpen ? '30px' : '250px',
            transition: 'width 700ms ease-in-out, padding 700ms ease-in-out',
            display: 'block',
            margin: '0 auto',
        },
        mainDivider: {
            bgcolor: '#DBF22E',
            height: '1px',
            width: !isOpen ? '40px' : '280px',
            alignSelf: 'center',
            opacity: !isOpen ? '0.5' : '0.35',
            marginBottom: '10px',
            transition: 'width 700ms ease-in-out, opacity 700ms ease-in-out',
        },
        text: {
            textAlign: 'center',
            color: '#eee',
            opacity: !isOpen ? 0 : 1,
            maxWidth: !isOpen ? 0 : '100%',
            overflow: 'hidden',
            transition: 'opacity 700ms ease-in-out, max-width 700ms ease-in-out',
            whiteSpace: 'nowrap',
        },
        icon: {
            justifyContent: 'center',
            color: '#eee',
            '& .MuiSvgIcon-root': {
                fontSize: isOpen ? 45 : 30,
                transition: 'font-size 700ms ease', // Aplica a transição na mudança de tamanho
            },        },
        iconMenu: {
            justifyContent: 'flex-end',
            color: '#DBF22E',
            padding: '10px 10px 0px 0px',
            '& .MuiSvgIcon-root': {
                fontSize: isOpen ? 45 : 30,
                transition: 'font-size 700ms ease', // Aplica a transição na mudança de tamanho
            },        },
        listItem: {
            justifyContent: 'center',
            width: '220px',
        },
        divider: {
            my: 1,
            bgcolor: '#DBF22E',
            width: !isOpen ? '30px' : '250px',
            alignSelf: 'center',
            opacity: !isOpen ? '0.35' : '0.25',
            transition: 'width 700ms ease-in-out, opacity 700ms ease-in-out',
        },
    };

    return styles;
}