import React from 'react';
import * as Icons from 'react-icons/md';
import { useNavigate } from 'react-router-dom'; 
import { useStyles } from './styles';


function RButton({ route }) {
    const navigate = useNavigate(); 
    const IconComponent = Icons[route.iconName];
    const styles = useStyles(route);

    const handleButtonClick = () => {
        navigate(route.path); 
    };

    return (
        <div style={styles.button} onClick={handleButtonClick}>
            {IconComponent && <IconComponent style={styles.icon} />}
            <p style={styles.text}>
                {route.label}
            </p>
        </div>
    );
}

export default RButton;
