import React, { Suspense, useState, useEffect } from 'react';
import RBody from '../../../../components/RBody';
import { useStyles } from './styles';
import { useAlert } from '../../../../services/apiService';
import RSkeletonTable from '../../../../components/RTable/RSkeletonTable';
const RTable = React.lazy(() => import('../../../../components/RTable'));

const BuscarClientesPage = () => {
  const { callApi } = useAlert();
  const styles = useStyles();
  const [clientes, setClientes] = useState([]);

  useEffect(() => {
    handleClientes();
  }, []);

  const handleClientes = async () => {
    try {
      const result = await callApi({
        path: '/clientes',
        method: 'GET',
        body: null,
        need_token: true
      });
      if (result.success) {
        if (result.data) {
          setClientes(result.data.reverse());
        } else {
          console.error('Dados de clientes não encontrados na resposta da API.');
        }
      } else {
        console.error('Erro ao obter clientes:', result.message);
      }
    } catch (error) {
      console.error('Erro ao obter clientes:', error);
    }
  };


  const columns = [
    { id: 'id', label: 'ID', minWidth: 50, type: 'number' },
    { id: 'nome', label: 'Nome', minWidth: 170, type: 'text' },
    { id: 'telefone', label: 'Telefone', minWidth: 100, type: 'text' },
    { id: 'estado', label: 'Estado', minWidth: 40, type: 'text' },
    { id: 'cidade', label: 'Cidade', minWidth: 40, type: 'text' },
    { id: 'bairro', label: 'Bairro', minWidth: 40, type: 'text' },
    { id: 'rua', label: 'Rua', minWidth: 40, type: 'text' },
    { id: 'numero', label: 'Numero', minWidth: 40, type: 'text' },
    { id: 'complemento', label: 'Complemento', minWidth: 40, type: 'text' },

    { id: 'ativo', label: 'Ativo', minWidth: 50, type: 'text' },
  ];

  const rows = clientes.map(item => ({
    id: item.codigo ?? '',
    nome: item.nome ?? '',
    telefone: item.telefone ?? '',
    email: item.email ?? '',
    estado: item.estado ?? '',
    cidade: item.cidade ?? '',
    bairro: item.bairro ?? '',
    rua: item.rua ?? '',
    numero: item.numero ?? '',
    complemento: item.complemento ?? '', 
    
    ativo: item.ativo ? 'Ativo' : 'Inativo'
  }));

  return (
    <RBody
      title="Buscar Clientes"
      titleColor='#eee'
    >
      <div style={styles.table}>
        <Suspense fallback={<RSkeletonTable />}>
          <RTable data={rows} columns={columns} columnToRedirect={'nome'} />
        </Suspense>
      </div>
    </RBody>
  );
}

export default BuscarClientesPage;
