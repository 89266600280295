import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid } from 'recharts';

function RBarChart({ data, databars, width, height }) {
    const renderCustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div style={{ backgroundColor: '#0A1126', border: '1px solid #ccc', padding: '10px', color: '#EEE' }}>
                    <p>{label}</p>
                    {payload.map((entry, index) => (
                        <div key={`item-${index}`} style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ width: '12px', height: '12px', backgroundColor: entry.color, marginRight: '10px', border: '1px solid #EEE' }}></div>
                            <p style={{ color: '#EEE' }}>{`${entry.name}: ${entry.value}`}</p>
                        </div>
                    ))}
                </div>
            );
        }
        return null;
    };

    const renderLegendText = (value) => (
        <span style={{ color: '#EEE' }}>{value}</span>
    );

    return (
        <BarChart width={width} height={height} data={data}>
            <CartesianGrid stroke="#0A1126" strokeOpacity={0.35} />
            {databars.map((bar, index) => (
                <Bar key={index} dataKey={bar.dataKey} fill={bar.color} name={bar.name} />
            ))}
            <XAxis dataKey="name" stroke="#DBF22E" tick={{ fill: '#EEE' }} tickLine={{ stroke: '#DBF22E' }} />
            <YAxis stroke="#DBF22E" tick={{ fill: '#EEE' }} tickLine={{ stroke: '#DBF22E' }} />
            <Tooltip content={renderCustomTooltip} />
            <Legend formatter={renderLegendText} />
        </BarChart>
    );
}

export default RBarChart;
