import React from 'react';

import { useStyles } from './styles';


function RAnalysisList({ title, data }) {

    const styles = useStyles();

    return (
        <div style={styles.containerStyle}>
            <h1 style={styles.titleStyle}>{title}</h1>
            <ul style={{ ...styles.listStyle, ...styles.webkitScrollbarStyles }}>
                {data.map((item, index) => (
                    <li key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', color: '#eee' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            <span style={{ color: '#DBF22E', marginRight: '8px', fontWeight: 'bold' }}>{index + 1}.</span>
                            <span style={{ flex: 1, textAlign: 'left', fontWeight: 'bold' }}>{item.name}</span>
                            <span style={{ color: '#2ABFB0', fontWeight: 'bold' }}>{item.value}</span>
                        </div>
                        <div style={styles.dividerStyle}></div>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default RAnalysisList;
