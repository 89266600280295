export function useFormFields(cliente) {

  const clienteFormFields = [
    {
      placeholder: 'Nome',
      type: 'text',
      width: 45,
      required: true,
      value: cliente.nome
    },
    {
      placeholder: 'Telefone',
      type: 'text',
      width: 45,
      required: false,
      value: cliente.telefone
    },
    {
      placeholder: 'Estado',
      type: 'text',
      width: 45,
      required: true,
      value: cliente.estado
    },
    {
      placeholder: 'Cidade',
      type: 'text',
      width: 45,
      required: true,
      value: cliente.cidade
    },
    {
      placeholder: 'Bairro',
      type: 'text',
      width: 45,
      required: true,
      value: cliente.bairro
    },
    {
      placeholder: 'Rua',
      type: 'text',
      width: 45,
      required: true,
      value: cliente.rua
    },
    {
      placeholder: 'Número',
      type: 'text',
      width: 45,
      required: true,
      value: cliente.numero
    },
    {
      placeholder: 'Complemento',
      type: 'text',
      width: 45,
      required: false,
      value: cliente.complemento
    },
    {
      id: 'Ativo',
      placeholder: 'Ativo',
      options: [
        { value: true, label: 'Ativo' },
        { value: false, label: 'Inativo' }
      ],
      type: 'dropdown',
      width: 45,
      required: true,
      value: cliente.ativo
    },
  ];

  return clienteFormFields;
}