import React, { useState, useEffect } from 'react';
import RSidebar from '../RSidebar';
import { Provider } from 'react-redux';
import store from '../../store';
import { useStyles } from './styles';


function RBody({ title, children, color, titleColor }) {
    const [drawerWidth, setDrawerWidth] = useState(340);
    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 700);

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth < 700);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    const styles = useStyles(isMobileView, drawerWidth, color);

    return (
        <Provider store={store}>
            <div style={styles.body}>
                <RSidebar drawerWidth={drawerWidth} setDrawerWidth={setDrawerWidth} />
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 50,
                    color: titleColor
                }}>
                    <h1>{title}</h1>
                </div>
                {children}
            </div>
        </Provider>
    );
}

export default RBody;
