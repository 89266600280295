import { useState, useEffect } from 'react';

export function useStyles() {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const styles = {
    containerStyle: {
      padding: '20px',
      backgroundColor: '#0A1126',
      borderRadius: '50px',
      textAlign: 'center',
      boxSizing: 'border-box',
      margin: '10px 10% 10px 10%',
      position: 'relative',
      cursor: 'pointer'
    },

    titleStyle: {
      color: '#eee',
      fontSize: '26px',
      fontWeight: 'bold',
    },
  }

  return styles;
}