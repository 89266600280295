import React from 'react';
import { Routes, Route } from 'react-router-dom';
import PrivateRoute from './services/privateRoute';

import Login from './views/Login';
import Dashboard from './views/Dashboard';
import Clientes from './views/Clientes';
import Financeiro from './views/Financeiro';
import Notas from './views/Notas';
import Mercadorias from './views/Mercadorias';
import Configuracoes from './views/Configuracoes';

import ListarClientes from './views/Clientes/ListarClientes';
import BuscarClientes from './views/Clientes/BuscarClientes';
import AdicionarClientes from './views/Clientes/AdicionarClientes';
import CadastroCliente from './views/Clientes/CadastroCliente';

import Usuarios from './views/Configuracoes/Usuarios'
import ListarUsuarios from './views/Configuracoes/Usuarios/ListarUsuarios';
import BuscarUsuarios from './views/Configuracoes/Usuarios/BuscarUsuarios';
import AdicionarUsuarios from './views/Configuracoes/Usuarios/AdicionarUsuarios';
import CadastroUsuario from './views/Configuracoes/Usuarios/CadastroUsuarios';

import Compras from './views/Mercadorias/Compras';
import ListarCompras from './views/Mercadorias/Compras/Listar';
import AdicionarCompras from './views/Mercadorias/Compras/Adicionar';
import BuscarCompras from './views/Mercadorias/Compras/Buscar';

import Rotas from './views/Rotas';
import MenuRotas from './views/Rotas/Rotas'
import AdicionarRotas from './views/Rotas/Rotas/AdicionarRotas'
import BuscarRotas from './views/Rotas/Rotas/BuscarRotas'
import ListarRotas from './views/Rotas/Rotas/ListarRotas'
import CadastroRota from './views/Rotas/Rotas/CadastroRota'

import Viagens from './views/Rotas/Viagens'
import IniciarViagem from './views/Rotas/Viagens/IniciarViagem'
import ListarViagens from './views/Rotas/Viagens/ListarViagens'
import CadastroViagem from './views/Rotas/Viagens/CadastroViagem';

export default function AppRoutes() {
    return (
        <Routes>
            <Route path="/" element={<PrivateRoute element={Dashboard} />} />

            <Route path="/login" element={<Login />} />

            <Route path="/dashboard" element={<PrivateRoute element={Dashboard} />} />
            
            <Route path="/clientes" element={<PrivateRoute element={Clientes} />} />
            <Route path="/clientes/listar" element={<PrivateRoute element={ListarClientes} />} />
            <Route path="/clientes/buscar" element={<PrivateRoute element={BuscarClientes} />} />
            <Route path="/clientes/adicionar" element={<PrivateRoute element={AdicionarClientes} />} />
            <Route path="/clientes/listar/:id" element={<PrivateRoute element={CadastroCliente} />} />

            <Route path="/financeiro" element={<PrivateRoute element={Financeiro} />} />
            <Route path="/notas" element={<PrivateRoute element={Notas} />} />

            <Route path="/configuracoes" element={<PrivateRoute element={Configuracoes} />} />
            <Route path="/configuracoes/usuarios" element={<PrivateRoute element={Usuarios} />} />
            <Route path="/configuracoes/usuarios/adicionar" element={<PrivateRoute element={AdicionarUsuarios} />} />
            <Route path="/configuracoes/usuarios/listar" element={<PrivateRoute element={ListarUsuarios} />} />
            <Route path="/configuracoes/usuarios/listar/:id" element={<PrivateRoute element={CadastroUsuario} />} />
            <Route path="/configuracoes/usuarios/buscar" element={<PrivateRoute element={BuscarUsuarios} />} />

            <Route path="/mercadorias" element={<PrivateRoute element={Mercadorias} />} />
            <Route path="/mercadorias/compras" element={<PrivateRoute element={Compras} />} />
            <Route path="/mercadorias/compras/adicionar" element={<PrivateRoute element={AdicionarCompras} />} />
            <Route path="/mercadorias/compras/listar" element={<PrivateRoute element={ListarCompras} />} />
            <Route path="/mercadorias/compras/buscar" element={<PrivateRoute element={BuscarCompras} />} />

            <Route path="/rotas/viagens" element={<PrivateRoute element={Viagens} />} />
            <Route path="/rotas/viagens/iniciar" element={<PrivateRoute element={IniciarViagem} />} />
            <Route path="/rotas/viagens/listar" element={<PrivateRoute element={ListarViagens} />} />
            <Route path="/rotas/viagens/listar/:id" element={<PrivateRoute element={CadastroViagem} />} />

            <Route path="/rotas" element={<PrivateRoute element={Rotas} />} />
            <Route path="/rotas/menu" element={<PrivateRoute element={MenuRotas} />} />
            <Route path="/rotas/adicionar" element={<PrivateRoute element={AdicionarRotas} />} />
            <Route path="/rotas/listar" element={<PrivateRoute element={ListarRotas} />} />
            <Route path="/rotas/buscar" element={<PrivateRoute element={BuscarRotas} />} />
            <Route path="/rotas/listar/:id" element={<PrivateRoute element={CadastroRota} />} />
        </Routes>
    );
}

