import { useSelector } from 'react-redux';

export function useStyles(isMobileView, drawerWidth, color) {
    let pLeft = '';

    const isOpen = useSelector(state => state.sidebar.isOpen);

    if (isMobileView){
        pLeft = isOpen ? '100px' : '50px'
    } else {
        pLeft = isOpen ? '300px' : '50px'
    }

    const styles = {
        body: {
            paddingLeft: pLeft,
            transition: 'padding-left 700ms ease',
            maxWidth: '100%',
            boxSizing: 'border-box',
            overflowX: 'hidden',
            backgroundColor:'#13214A',
            height: '100vh',
        },
        header: {
            wordWrap: 'break-word',
            overflowWrap: 'break-word',
            whiteSpace: 'normal',
        }
    }
    return styles;
}