import React, { useState, useEffect } from 'react';
import RBody from '../../components/RBody';
import { useStyles } from './styles';
import RPieChart from '../../components/RPieChart';
import RBarChart from '../../components/RBarChart';
import RAnalysisList from '../../components/RAnalysisList';

const DashboardPage = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);

  const styles = useStyles();

  const data = [
    { "name": "Notas no Prazo", "value": 400, "color": "#0ECED6" },
    { "name": "Notas Vencidas", "value": 300, "color": "#13214A" },
    { "name": "Notas Pagas", "value": 300, "color": "#DBF22E" },
  ];

  const databar = [
    { name: 'Janeiro', value1: 400, value2: 240 },
    { name: 'Fevereiro', value1: 300, value2: 139 },
    { name: 'Março', value1: 200, value2: 980 },
    { name: 'Abril', value1: 278, value2: 390 },
    { name: 'Maio', value1: 189, value2: 480 },
    { name: 'Junho', value1: 239, value2: 380 },
    { name: 'Julho', value1: 349, value2: 430 },
  ];

  const databars = [
    { name: 'Líquido', dataKey: 'value1', color: '#0ECED6' },
    { name: 'Bruto', dataKey: 'value2', color: '#1C306F' },
  ];

  const chartStyle = {
    padding: '20px',
    backgroundColor: '#0A1126',
    borderRadius: '50px',
    textAlign: 'center',
    boxSizing: 'border-box',
    margin: '10px',
    flexDirection: 'column', // Organiza os filhos em coluna
  };


  const analysisTitles = [
    {
      title: "Produtos mais vendidos", data: [
        { name: 'Toalha de mesa', value: '127,04 Mil' },
        { name: 'AirFryer', value: '127,04 Mil' },
        { name: 'Jogo de Pratos', value: '127,04 Mil' },
        { name: 'Jogo de Toalhas', value: '127,04 Mil' },
        { name: 'Xiaomi mi note 9 pro max plus', value: '127,04 Mil' },
        { name: 'Fogão 100watts phillips wallitta', value: '127,04 Mil' },
      ]
    },
    {
      title: "Produtos que mais deram lucro", data: [
        { name: 'Toalha de mesa', value: '127,04 Mil' },
        { name: 'AirFryer', value: '127,04 Mil' },
        { name: 'Jogo de Pratos', value: '127,04 Mil' },
        { name: 'Jogo de Toalhas', value: '127,04 Mil' },
        { name: 'Xiaomi mi note 9 pro max plus', value: '127,04 Mil' },
        { name: 'Fogão 100watts phillips wallitta', value: '127,04 Mil' },
      ]
    },
    {
      title: "Média de vendas dos produtos por viagem", data: [
        { name: 'Toalha de mesa', value: '127,04 Mil' },
        { name: 'AirFryer', value: '127,04 Mil' },
        { name: 'Jogo de Pratos', value: '127,04 Mil' },
        { name: 'Jogo de Toalhas', value: '127,04 Mil' },
        { name: 'Xiaomi mi note 9 pro max plus', value: '127,04 Mil' },
        { name: 'Fogão 100watts phillips wallitta', value: '127,04 Mil' },
      ]
    },
    {
      title: "Clientes que mais compraram", data: [
        { name: 'Toalha de mesa', value: '127,04 Mil' },
        { name: 'AirFryer', value: '127,04 Mil' },
        { name: 'Jogo de Pratos', value: '127,04 Mil' },
        { name: 'Jogo de Toalhas', value: '127,04 Mil' },
        { name: 'Xiaomi mi note 9 pro max plus', value: '127,04 Mil' },
        { name: 'Fogão 100watts phillips wallitta', value: '127,04 Mil' },
      ]
    },
    {
      title: "Clientes que mais deram lucro", data: [
        { name: 'Toalha de mesa', value: '127,04 Mil' },
        { name: 'AirFryer', value: '127,04 Mil' },
        { name: 'Jogo de Pratos', value: '127,04 Mil' },
        { name: 'Jogo de Toalhas', value: '127,04 Mil' },
        { name: 'Xiaomi mi note 9 pro max plus', value: '127,04 Mil' },
        { name: 'Fogão 100watts phillips wallitta', value: '127,04 Mil' },
      ]
    },
    {
      title: "Rotas que mais dão lucro", data: [
        { name: 'Toalha de mesa', value: '127,04 Mil' },
        { name: 'AirFryer', value: '127,04 Mil' },
        { name: 'Jogo de Pratos', value: '127,04 Mil' },
        { name: 'Jogo de Toalhas', value: '127,04 Mil' },
        { name: 'Xiaomi mi note 9 pro max plus', value: '127,04 Mil' },
        { name: 'Fogão 100watts phillips wallitta', value: '127,04 Mil' },
      ]
    },
    {
      title: "Média de venda em cada rota", data: [
        { name: 'Toalha de mesa', value: '127,04 Mil' },
        { name: 'AirFryer', value: '127,04 Mil' },
        { name: 'Jogo de Pratos', value: '127,04 Mil' },
        { name: 'Jogo de Toalhas', value: '127,04 Mil' },
        { name: 'Xiaomi mi note 9 pro max plus', value: '127,04 Mil' },
        { name: 'Fogão 100watts phillips wallitta', value: '127,04 Mil' },
      ]
    },
  ];

  return (
    <RBody title="Dashboard" titleColor='#eee'>
      <div style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        marginTop: '20px',
        minWidth: '300px',
        minHeight: '220px',
        
      }}>
        <div style={{
          ...chartStyle,
          width: windowWidth * 0.45,
          minWidth: '300px',
          minHeight: '290px',
          boxSizing: 'border-box',
          margin: '10px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '250px'
        }}>
          <h1 style={{ color: '#eee', fontSize: '16px', marginTop: '-20px' }}>
            Notas Vencidas X Notas Pagas X Notas no Prazo
          </h1>
          <RPieChart data={data} size={windowWidth / 12} width={windowWidth / 2.25 > 320 ? windowWidth / 2.25 : 320} height={windowWidth / 6} />
        </div>

        <div style={{ ...chartStyle, width: windowWidth * 0.45, minWidth: '300px', minHeight: '220px' }}>
          <h1 style={{ color: '#eee', fontSize: '16px', minWidth: '300px', minHeight: '220px', marginBottom: '-150px' }}>
            Renda Líquida X Renda Bruta
          </h1>
          <div style={{ marginLeft: '-30px' }}>
            <RBarChart data={databar} databars={databars} width={windowWidth / 2.25 > 290 ? windowWidth / 2.25 : 290} height={windowWidth / 6 > 290 ? windowWidth / 6 : 290} />
          </div>
        </div>
      </div>

      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', marginTop: '20px' }}>
        {analysisTitles.map((item, index) => (
          <RAnalysisList
            key={index}
            title={item.title}
            data={item.data}
            style={chartStyle}
          />
        ))}
      </div>
    </RBody>
  );
}

export default DashboardPage;
