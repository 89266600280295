import { useState, useEffect } from 'react';

export const useStyles = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const styles = {
    containerStyle: {
      padding: '15px 10px',
      backgroundColor: '#0A1126',
      borderRadius: '20px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap',
      margin: '10px 10% 10px 10%'
    },
    textContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    nomeCliente: {
      color: '#eee',
      fontSize: '26px',
      fontWeight: 'bold',
      marginLeft: '20px'
    },
    button: {
      fontWeight: 'bold',
      fontSize: '16px',
      color: '#0A1126',
      backgroundColor: '#2ABFB0',
      padding: '10px',
      textAlign: 'center',
      cursor: 'pointer',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      borderRadius: '4px',
      userSelect: 'none',
      margin: '5px',
      width: windowWidth >= 800 ? null : '100%'
    },
    buttonContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: windowWidth >= 800 ? 'flex-end' : 'center',
      alignItems: 'center',
      width: windowWidth >= 800 ? null : '100%'
    },
    checkIcon: {
      marginLeft: '20px',
      color: '#eee',
      fontSize: '30px',
      cursor: 'pointer'
    },
    arrowIcon: {
      color: '#eee',
      fontSize: '40px',
      cursor: 'pointer'
    }
  };

  return styles;
};
