import React from 'react';
import RBody from '../../components/RBody';
import { useStyles } from './styles';
import RRoutesTable from '../../components/RRoutesTable';

function Clientes() {
    const styles = useStyles();

    const routeData = [
        { label: "Buscar", size: 150, iconName: "MdPersonSearch", iconColor: '#DBF22E', textColor: '#eee', backgroundColor: '#0A1126', path: '/clientes/buscar' },
        { label: "Adicionar", size: 150, iconName: "MdPersonAddAlt1", iconColor: '#DBF22E', textColor: '#eee', backgroundColor: '#0A1126', path: '/clientes/adicionar' },
        { label: "Listar", size: 150, iconName: "MdGroups", iconColor: '#DBF22E', textColor: '#eee', backgroundColor: '#0A1126', path: '/clientes/listar' },
    ];

    return (
        <RBody
            title="Clientes"
            titleColor='#eee'
        >
            <RRoutesTable routes={routeData} />
        </RBody>
    );
}

export default Clientes;
