import React, { useState, useEffect } from 'react';
import RBody from '../../../../components/RBody';
import RForm from '../../../../components/RForm';
import { useStyles } from './styles';
import { useAlert } from '../../../../services/apiService';
import { useParams } from 'react-router-dom';
import { useFormFields } from './fields';

const CadastroUsuariosPage = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [usuario, setUsuario] = useState({});
    const [loading, setLoading] = useState(true);

    const { id } = useParams();

    const { callApi } = useAlert();

    const handleUpdate = async (formData) => {
        try {
            const result = await callApi({
                path: `/users/${id}`,
                method: 'PUT',
                body: formData,
                need_token: true,
                alerta: true
            });
            if (result.success) {
                if (result.data) {
                    setUsuario(result.data);
                } else {
                    console.error('Erro ao criar usuario.');
                }
            } else {
                console.error('Erro ao criar usuario:', result.message);
            }
        } catch (error) {
            console.error('Erro ao criar usuario:', error);
        }
    };

    const getUsuario = async () => {
        try {
            const result = await callApi({
                path: `/users/${id}`,
                method: 'GET',
                need_token: true
            });
            if (result.success) {
                if (result.data) {
                    setUsuario(result.data);
                    setLoading(false);
                } else {
                    console.error('Erro ao buscar usuario.');
                    setLoading(false);
                }
            } else {
                console.error('Erro ao buscar usuario:', result.message);
                setLoading(false);
            }
        } catch (error) {
            console.error('Erro ao buscar usuario:', error);
            setLoading(false);
        }
    };

    useEffect(() => {

        getUsuario();

        const handleResize = () => {
            setWindowWidth(window.innerWidth);
            setWindowHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, []);

    const styles = useStyles();
    const usuarioFormFields = useFormFields(usuario);

    return (
        <RBody title={usuario.nome || 'Carregando...'} titleColor='#eee'>
            {loading ? (
                <div>Loading...</div>
            ) : (
                <RForm fields={usuarioFormFields} onSubmit={handleUpdate} />
            )}
        </RBody>
    );
}

export default CadastroUsuariosPage;
