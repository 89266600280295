import React from 'react';
import RBody from '../../components/RBody';
import { useStyles } from './styles';
import RRoutesTable from '../../components/RRoutesTable';

function Rotas() {
    const styles = useStyles();

    const routeData = [
        { label: "Viagens", size: 150, iconName: "MdShoppingBag", iconColor: '#DBF22E', textColor: '#eee', backgroundColor: '#0A1126', path: '/rotas/viagens' },
        { label: "Rotas", size: 150, iconName: "MdTurnSharpLeft", iconColor: '#DBF22E', textColor: '#eee', backgroundColor: '#0A1126', path: '/rotas/menu' },
    ];

    return (
        <RBody
            title="Rotas"
            titleColor='#eee'
        >
            <RRoutesTable routes={routeData} />
        </RBody>
    );
}

export default Rotas;
