import React, { useState } from 'react';
import { TextField, IconButton, InputAdornment, Select, MenuItem, InputLabel, FormControl, OutlinedInput, Chip, Autocomplete, Box } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import RAlert from '../RAlert';
import { useStyles } from './styles';
import './styles.css';
import QuantitySelect from '../RQuantitySelect';
import { Layout } from 'antd';

function RForm({ fields, onSubmit }) {
    const styles = useStyles();
    const [fieldValues, setFieldValues] = useState(() => {
        const initialValues = {};
        fields.forEach(field => {
            if (field.placeholder === 'Ativo') {
                initialValues[field.placeholder] = field.value === false ? false : field.value || '';
            } else if (Array.isArray(field.value) && field.type === 'select' && field.value) {
                initialValues[field.id] = field.value.map(val => val.value);
            } else {
                initialValues[field.placeholder] = field.value || '';
            }
        });
        return initialValues;
    });
    const [showVisibility, setShowVisibility] = useState({});
    const [showAlert, setShowAlert] = useState(false);
    const [missingFields, setMissingFields] = useState([]);
    const [menuOpen, setMenuOpen] = useState(false);
    const { Content } = Layout;

    const handleChange = (field, value) => {
        if (Array.isArray(value)) {
            setFieldValues(prev => ({ ...prev, [field]: value.map(item => item.value) }));
            console.log(fieldValues);
        } else {
            setFieldValues(prev => ({ ...prev, [field]: value }));
        }
    };

    const handleChangeDropdown = (field, value) => {
        value = value ?? false;
        setFieldValues(prev => ({ ...prev, [field]: value }));
    };


    const toggleVisibility = field => {
        setShowVisibility(prev => ({ ...prev, [field]: !prev[field] }));
    };

    const handleMouseDown = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const renderDropdownField = (field) => {
        const { id, placeholder, options, width, required } = field;
        const fieldValue = fieldValues[id];
        let fieldValueAjustado

        if (fieldValue === 'Ativo' || fieldValue === 'Inativo') {
            fieldValueAjustado = fieldValue === 'Ativo' ? true : false;
        } else {
            fieldValueAjustado = fieldValue;
        }

        const placeholderAjustado = placeholder + (required ? ' *' : '');

        return (
            <FormControl sx={{ width: `${width}%`, ...styles.input }} key={id}>
                <InputLabel id={`${id}-label`} style={{ color: 'rgba(238,238,238,0.5)' }}>
                    {placeholderAjustado}
                </InputLabel>
                <Select
                    labelId={`${id}-label`}
                    value={fieldValueAjustado}
                    onChange={e => handleChangeDropdown(id, e.target.value)}
                    input={<OutlinedInput id={id} label={placeholder} />}
                    MenuProps={{ style: { maxHeight: 300 } }}
                >
                    {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    };


    const renderAutocomplete = (field) => {
        return (
            <Layout>
                <Content style={{ padding: '50px', maxWidth: '600px', margin: '0 auto' }}>
                    <h1>Place Your Order</h1>
                    <QuantitySelect />
                </Content>
            </Layout>
        );
    };

    const renderSelectField = (field) => {
        const { id, placeholder, options, width, required } = field;
        const fieldValue = fieldValues[id] || [];

        const placeholderAjustado = placeholder + (required ? ' *' : '');

        return (
            <FormControl sx={{ width: `${width}%`, ...styles.input }} key={id}>
                <InputLabel id={`${id}-label`} style={{ color: 'rgba(255, 255, 255, 0.6)' }}>{placeholderAjustado}</InputLabel>
                <Select
                    labelId={`${id}-label`}
                    multiple
                    value={options.filter(option => fieldValue.includes(option.value))}
                    onChange={e => handleChange(id, e.target.value)}
                    input={<OutlinedInput id={id} label={placeholder} />}
                    renderValue={(selected) => (
                        <div style={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                                <Chip key={value.value} style={{color: '#eee'}} label={value.label} />
                            ))}
                        </div>
                    )}
                    MenuProps={{ style: { maxHeight: 300 } }}
                >
                    {options.map((option) => (
                        <MenuItem key={option.value} value={option}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    };

    const normalizeKeys = (data) => {
        const normalizedData = {};
        Object.keys(data).forEach(key => {
            const normalizedKey = key.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
            normalizedData[normalizedKey] = data[key] === "" ? null : data[key];
        });
        return normalizedData;
    };

    const handleSubmit = () => {
        const requiredFields = fields.filter(f => f.required).map(f => f.placeholder);
        const missing = requiredFields.filter(rf => fieldValues[rf] === undefined || fieldValues[rf] === '');

        if (missing.length > 0) {
            setMissingFields(missing);
            setShowAlert(true);
        } else {
            const normalizedFieldValues = normalizeKeys(fieldValues);

            onSubmit(normalizedFieldValues);
            setShowAlert(false);
        }
    };

    const handleAlertClose = () => {
        setShowAlert(false);
    };

    const renderInputField = (field) => {
        const { placeholder, type, width, required, hidden = false, value } = field;
        const inputLabel = required ? `${placeholder} *` : placeholder;
        const fieldValue = fieldValues[placeholder];
        const isFieldVisible = showVisibility[placeholder] || false;

        const endAdornment = hidden ? (
            <InputAdornment position="end">
                <IconButton
                    onClick={() => toggleVisibility(placeholder)}
                    edge="end"
                    sx={{ color: '#eee' }}
                >
                    {isFieldVisible ? <VisibilityOff /> : <Visibility />}
                </IconButton>
            </InputAdornment>
        ) : null;

        return (
            <TextField
                label={inputLabel}
                InputLabelProps={{ style: { color: 'rgba(255, 255, 255, 0.6)' } }}
                className="text-field"
                key={placeholder}
                type={hidden && !isFieldVisible ? 'password' : type}
                placeholder={inputLabel}
                value={fieldValue}
                onChange={e => handleChange(placeholder, e.target.value)}
                sx={{ ...styles.input, width: `${width}%` }}
                InputProps={{ endAdornment }}
            />
        );
    };

    const renderField = (field) => {
        switch (field.type) {
            case 'select':
                return renderSelectField(field);
            case 'dropdown':
                return renderDropdownField(field);
            case 'autocomplete':
                return renderAutocomplete(field);
            case 'input':
                return renderInputField(field);
            default:
                return renderInputField(field);
        }
    };


    return (
        <div>
            <div style={styles.formContainer}>
                {fields.map(field => renderField(field))}
            </div>
            <div style={styles.buttonContainer}>
                <div style={styles.button} onClick={handleSubmit}>
                    SALVAR
                </div>
            </div>
            {showAlert && (

                <RAlert
                    title="Erro de validação"
                    message={`Preencha todos os campos obrigatórios: \n• ${missingFields.join('\n• ')}`}
                    success={false}
                    onClose={handleAlertClose}
                />
            )}
        </div>
    );
}

export default RForm;
