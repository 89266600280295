import React from 'react';
import RBody from '../../components/RBody';
import { useStyles } from './styles';
import RRoutesTable from '../../components/RRoutesTable';

function Notas() {
    const styles = useStyles();

    const routeData = [
        { label: "Adicionar", size: 150, iconName: "MdPlaylistAdd", iconColor: '#DBF22E', textColor: '#eee', backgroundColor: '#0A1126', path: '/notas/listar' },
        { label: "Listar", size: 150, iconName: "MdFormatListBulleted", iconColor: '#DBF22E', textColor: '#eee', backgroundColor: '#0A1126', path: '/notas/listar' },
    ];

    return (
        <RBody
            title="Notas"
            titleColor='#eee'
        >
            <RRoutesTable routes={routeData} />
        </RBody>
    );
}

export default Notas;
