import React, { useState, useEffect } from 'react';
import RBody from '../../../../components/RBody';
import { useStyles } from './styles';
import { useAlert } from '../../../../services/apiService';
import { useParams } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

const CadastroViagemPage = () => {
    const { callApi } = useAlert();
    const styles = useStyles();
    const [viagem, setViagem] = useState([]);
    const [loading, setLoading] = useState(true);

    const { id } = useParams();

    useEffect(() => {
        getViagem();
    }, []);

    const getViagem = async () => {
        try {
            const result = await callApi({
                path: `/viagens/${id}`,
                method: 'GET',
                need_token: true
            });
            if (result.success) {
                if (result.data) {
                    setViagem(result.data);
                    setLoading(false);

                    console.log(viagem.clientes_concluidos);
                } else {
                    console.error('Erro ao buscar viagem.');
                    setLoading(false);
                }
            } else {
                console.error('Erro ao buscar viagem:', result.message);
                setLoading(false);
            }
        } catch (error) {
            console.error('Erro ao buscar viagem:', error);
            setLoading(false);
        }
    };

    const handleConcluido = async (cliente, concluido) => {
        try {
            const updatedConcluidos = viagem.clientes_concluidos.map(c =>
                c.cliente && c.cliente._id && c.cliente._id === cliente._id ? { ...c, concluido: concluido } : c
            );


            const result = await callApi({
                path: `/viagens/${id}`,
                method: 'PUT',
                body: { ...viagem, clientes_concluidos: updatedConcluidos },
                need_token: true,
            });

            if (result.success) {
                if (result.data) {
                    setViagem(result.data);
                } else {
                    console.error('Erro ao atualizar status do cliente.');
                }
            } else {
                console.error('Erro ao atualizar status do cliente:', result.message);
            }
        } catch (error) {
            console.error('Erro ao atualizar status do cliente:', error);
        }
    };

    return (
        <RBody
            title={(!loading && viagem.rota.nome && viagem.data_inicio)
                ? `${viagem.rota.nome} - ${new Date(viagem.data_inicio).toLocaleDateString('pt-BR', {
                    day: '2-digit',
                    month: '2-digit'
                })}`
                : 'Carregando...'}
            titleColor='#eee'
        >
            {!loading && (
                viagem.clientes_concluidos.map(clienteObj => {
                    const cliente = clienteObj.cliente;
                    if (!cliente) return null;

                    return cliente.ativo && viagem.rota.clientes.includes(cliente._id) ? (
                        <div style={styles.containerStyle}>
                            <div style={styles.textContainer}>
                                {clienteObj.concluido ?
                                    <CheckBoxIcon style={styles.checkIcon} onClick={() => { handleConcluido(cliente, false) }} />
                                    :
                                    <CheckBoxOutlineBlankIcon style={styles.checkIcon} onClick={() => { handleConcluido(cliente, true) }} />
                                }
                                <span style={styles.nomeCliente}>{cliente.nome}</span>
                            </div>
                            <div style={styles.buttonContainer}>
                                <div style={styles.button}>
                                    ADICIONAR VENDA
                                </div>

                                {clienteObj.concluido ?
                                    <div style={styles.button} onClick={() => { handleConcluido(cliente, false) }}>
                                        NÃO CONCLUÍDO
                                    </div>
                                    :
                                    <div style={styles.button} onClick={() => { handleConcluido(cliente, true) }}>
                                        CONCLUÍDO
                                    </div>
                                }

                                <KeyboardArrowDownIcon style={styles.arrowIcon} />
                            </div>
                        </div>
                    ) : null;
                })
            )}

        </RBody>
    );
}

export default CadastroViagemPage;
