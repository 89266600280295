const initialState = {
    isOpen: localStorage.getItem('sidebarState') ? JSON.parse(localStorage.getItem('sidebarState')) : true
  };
  
  export function sidebarReducer(state = initialState, action) {
    switch (action.type) {
      case 'TOGGLE_SIDEBAR':
        try {
            const serializedState = JSON.stringify(action.payload);
            localStorage.setItem('sidebarState', serializedState);
          } catch (e) {
            console.error("Could not save state", e);
          }
        return {
          ...state,
          isOpen: !state.isOpen
        };
      default:
        return state;
    }
  }
  