export function useFormFields(usuario) {


  const usuarioFormFields = [
    {
      placeholder: 'Nome',
      type: 'text',
      width: 45,
      required: true,
      value: usuario.nome
    },
    {
      placeholder: 'Email',
      type: 'text',
      width: 45,
      required: true,
      value: usuario.email
    },
    {
      placeholder: 'Senha',
      type: 'text',
      width: 45,
      required: false,
      value: ''
    },
    {
      placeholder: 'Telefone',
      type: 'text',
      width: 45,
      required: false,
      value: usuario.telefone
    },
    {
      id: 'Ativo',
      placeholder: 'Ativo',
      options: [
        { value: true, label: 'Ativo' },
        { value: false, label: 'Inativo' }
      ],
      type: 'dropdown',
      width: 45,
      required: true,
      value: usuario.ativo
    },
  ];

  return usuarioFormFields;
}