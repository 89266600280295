import React from 'react';
import RouteButton from './RouteButton';
import { useStyles } from './styles';


function RRoutesTable({ routes }) {
  
  const styles = useStyles();

  return (
    <div style={styles.container}>
      {routes.map((route, index) => (
        <div key={index} style={{...styles.item, minWidth: `${route.size}px`, maxWidth: `${route.size}px`}}>
          <RouteButton route={route} />
        </div>
      ))}
    </div>
  );
}

export default RRoutesTable;
