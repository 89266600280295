import React from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';

function RPieChart({ data, size, width, height }) {
    const total = data.reduce((acc, entry) => acc + entry.value, 0); 

    const renderCustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            const { name, value, color } = payload[0].payload;
            const percentage = ((value / total) * 100).toFixed(2);
            return (
                <div style={{ backgroundColor: '#0A1126', border: '1px solid #ccc', padding: '10px', color: '#EEE', display: 'flex', alignItems: 'center' }}>
                    <div style={{ width: '12px', height: '12px', backgroundColor: color, marginRight: '10px', border: '1px solid #EEE' }}></div>
                    <p>{`${name}: ${percentage}%`}</p>
                </div>
            );
        }
        return null;
    };

    const renderColorfulLegendText = (value, entry) => {
        
        const item = data.find(d => d.name === value);
        if (item) {
            const percentage = ((item.value / total) * 100).toFixed(2);
            return (
                <span style={{ color: '#EEE', marginLeft: '10px', display: 'flex', alignItems: 'center', fontSize: '12px' }}>
                    <div style={{ width: '12px', height: '12px', backgroundColor: item.color, marginRight: '10px', border: '1px solid #EEE' }}></div>
                    {`${value} (${percentage}%)`}
                </span>
            );
        }
        return null;  
    };

    return (
        <PieChart width={width} height={height}>
            <Pie
                data={data}
                outerRadius={size}
                fill="#8884d8"
                dataKey="value"
                stroke='none'
                labelLine={false}
            >
                {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
            </Pie>
            <Tooltip content={renderCustomTooltip} />
            <Legend layout="vertical" align="left" iconSize={0} verticalAlign="middle" formatter={renderColorfulLegendText} />
        </PieChart>
    );
}

export default RPieChart;
