import React, { useState, useEffect } from 'react';
import RBody from '../../../components/RBody';
import RForm from '../../../components/RForm';
import { useStyles } from './styles';
import { useAlert } from '../../../services/apiService';
import { useParams } from 'react-router-dom';
import { useFormFields } from './fields';

const CadastroClientesPage = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [cliente, setCliente] = useState({});
    const [loading, setLoading] = useState(true);

    const { id } = useParams();

    const { callApi } = useAlert();

    const handleUpdate = async (formData) => {
        
        try {
            const result = await callApi({
                path: `/clientes/${id}`,
                method: 'PUT',
                body: formData,
                need_token: true,
                alerta: true
            });
            if (result.success) {
                if (result.data) {
                    setCliente(result.data);
                } else {
                    console.error('Erro ao criar cliente.');
                }
            } else {
                console.error('Erro ao criar cliente:', result.message);
            }
        } catch (error) {
            console.error('Erro ao criar cliente:', error);
        }
    };

    const getCliente = async () => {
        try {
            const result = await callApi({
                path: `/clientes/${id}`,
                method: 'GET',
                need_token: true
            });
            if (result.success) {
                if (result.data) {
                    setCliente(result.data);
                    setLoading(false);
                } else {
                    console.error('Erro ao buscar cliente.');
                    setLoading(false);
                }
            } else {
                console.error('Erro ao buscar cliente:', result.message);
                setLoading(false);
            }
        } catch (error) {
            console.error('Erro ao buscar cliente:', error);
            setLoading(false);
        }
    };

    useEffect(() => {

        getCliente();

        const handleResize = () => {
            setWindowWidth(window.innerWidth);
            setWindowHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, []);

    const styles = useStyles();
    const clienteFormFields = useFormFields(cliente);

    return (
        <RBody title={cliente.nome || 'Carregando...'} titleColor='#eee'>
            {loading ? (
                <div>Loading...</div>
            ) : (
                <RForm fields={clienteFormFields} onSubmit={handleUpdate} />
            )}
        </RBody>
    );
}

export default CadastroClientesPage;
