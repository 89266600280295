import React from 'react';
import RBody from '../../components/RBody';
import { useStyles } from './styles';
import RRoutesTable from '../../components/RRoutesTable';

function Financeiro() {
    const styles = useStyles();

    const routeData = [
        { label: "Receber", size: 150, iconName: "MdAttachMoney", iconColor: '#DBF22E', textColor: '#eee', backgroundColor: '#0A1126', path: '/financeiro/receber' },
        { label: "Pagar", size: 150, iconName: "MdMoneyOff", iconColor: '#DBF22E', textColor: '#eee', backgroundColor: '#0A1126', path: '/financeiro/pagar' },
        { label: "Balanço", size: 150, iconName: "MdOutlineBalance", iconColor: '#DBF22E', textColor: '#eee', backgroundColor: '#0A1126', path: '/financeiro/balanco' },
    ];

    return (
        <RBody
            title="Financeiro"
            titleColor='#eee'
        >
            <RRoutesTable routes={routeData} />
        </RBody>
    );
}

export default Financeiro;
