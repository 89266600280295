import React, { useState, useEffect } from 'react';
import RBody from '../../../../components/RBody';
import RForm from '../../../../components/RForm';
import { useStyles } from './styles';
import { useAlert } from '../../../../services/apiService';

const AdicionarClientesPage = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [mercadorias, setMercadorias] = useState([]);
    const [optionsMercadoria, setOptionsMercadoria] = useState([]);

    const { callApi } = useAlert();

    const handleCreate = async (formData) => {
        try {
            const result = await callApi({
                path: '/compra/store',
                method: 'POST',
                body: formData,
                need_token: true,
                alerta: true
            });
            if (result.success) {
                if (result.data) {

                } else {
                    console.error('Erro ao criar cliente.');
                }
            } else {
                console.error('Erro ao criar cliente:', result.message);
            }
        } catch (error) {
            console.error('Erro ao criar cliente:', error);
        }
    };

    useEffect(() => {
        const handleProdutos = async () => {
            try {
                const result = await callApi({
                    path: '/mercadorias',
                    method: 'GET',
                    need_token: true
                });
                if (result.success) {
                    setMercadorias(result.data.reverse());
                    const options = result.data.map(mercadoria => ({
                        name: mercadoria.nome,
                        quantity: 0,
                        _id: mercadoria._id
                    }));
                    setOptionsMercadoria(options);
                } else {
                    console.error('Erro ao carregar mercadorias:', result.message);
                }
            } catch (error) {
                console.error('Erro ao carregar mercadorias:', error);
            }
        };

        handleProdutos();
    }, []);  // Dependências vazias indicam que isso só é executado na montagem do componente


    const styles = useStyles();

    const clienteFormFields = [
        {
            placeholder: 'Valor',
            type: 'number',
            width: 90,
            required: true
        },
        {
            id: 'produtos',
            type: 'autocomplete',
            placeholder: 'Escolha produtos',
            width: 90,
            required: true,
            options: optionsMercadoria,
            styles: useStyles()
        }







    ];

    return (
        <RBody title="Adicionar Cliente" titleColor='#eee'>

            <RForm fields={clienteFormFields} onSubmit={handleCreate} />

        </RBody>
    );
}

export default AdicionarClientesPage;
