import { createStore, combineReducers } from 'redux';
import { sidebarReducer } from './reducers/sidebarReducer';

const rootReducer = combineReducers({
  sidebar: sidebarReducer,
  // Outros reducers aqui
});

const store = createStore(rootReducer);

export default store;
